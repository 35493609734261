import React, { useState, useEffect } from "react";
import Input from "../components/Input";
import Button from "../components/Button";
import "../styles/editTeam.scss";
import "firebase/firestore";
import "firebase/storage";
import firebase from "../Config/firebaseConfig";
import LoadingSpinner from "../components/LoadingSpinner";
import AddImage from "../components/AddImage";

const db = firebase.firestore();
const storage = firebase.storage();
const storageRef = storage.ref();

const EditTeam = ({ history }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [images, setImages] = useState([]);
  const [teamSize, setTeamSize] = useState(null);
  const [loading, setLoading] = useState(true);

  const updateTeam = async () => {
    const x = teamSize - data.length;
    const num = x > 0 && new Array(teamSize - data.length).fill("");
    const uploaded = [];

    const uploadImages = () => {
      if (images.length > 0) {
        images.forEach(image => {
          setLoading(true);
          if (image) uploaded.push(image);
          image &&
            storageRef
              .child(`/team/${image.name}`)
              .put(image)
              .on("state_changed", snapshot => {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                if (progress === 100) uploaded.pop();
                if (progress === 100 && uploaded.length === 0) {
                  updateTeam();
                  removeMembers();
                  setLoading(false);
                  history.push("/");
                }
              });
        });
      } else {
        updateTeam();
        removeMembers();
        setLoading(false);
        history.push("/");
      }
    };

    const updateTeam = () =>
      data.map((teamMember, i) =>
        db
          .collection("team")
          .doc(`teamMember${i + 1}`)
          .set(teamMember)
          .catch(error => setError(error))
      );

    const removeMembers = () =>
      num &&
      num.map(
        (x, i) =>
          num.length > 0 &&
          db
            .collection("team")
            .doc(`teamMember${data.length + i + 1}`)
            .delete()
            .catch(error => setError(error))
      );

    uploadImages();
  };

  const handleOnChange = (e, field, num = "") => {
    let newData = [...data];
    let teamMember = { ...newData[num] };
    let imageArr = [...images];

    if (field.includes("image") && e.target.files) {
      teamMember.image = e.target.files[0].name;
      imageArr[num] = e.target.files[0];
      setImages(imageArr);
    } else {
      teamMember[field] = e.target.value;
    }

    newData[num] = teamMember;
    setData(newData);
  };

  useEffect(() => {
    let arr = [];
    const getData = async () => {
      await db
        .collection("team")
        .get()
        .then(docs => {
          docs.forEach(doc => arr.push(doc.data()));
          setData(arr);
          setTeamSize(arr.length);
        })
        .finally(() => setLoading(false));
    };

    getData();
  }, []);

  const removeTeamMember = num => {
    let newData = [...data];

    newData.splice(num, 1);
    setData(newData);
  };

  const addTeamMember = () => {
    setData(prevState => [...prevState, { name: "", bio: "", image: "" }]);
  };

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    data && (
      <div className="edit-team">
        {loading && <LoadingSpinner />}
        <h2>Edit Team</h2>
        <div className="edit-team__container">
          {data.map((teamMember, i) => (
            <React.Fragment key={i + 1}>
              <h3>Team Member {i + 1}</h3>
              <div
                className="edit-team__section"
                style={{ marginBottom: "50px" }}
              >
                <img
                  src={require("../imgs/deleteIcon.svg")}
                  className="delete-icon"
                  alt="delete"
                  onClick={() => removeTeamMember(i)}
                />
                <Input
                  onChange={e => handleOnChange(e, "name", i)}
                  value={teamMember.name}
                  placeholder="Name"
                />
                <Input
                  onChange={e => handleOnChange(e, "image", i)}
                  type="file"
                  placeholder="Image"
                />
                          <AddImage
            handleOnChange={e => handleOnChange(e, "image", i)}
            label="Image 1"
            value={teamMember.image}
          />
                <Input
                  type="textarea"
                  maxLength="500"
                  onChange={e => handleOnChange(e, "bio", i)}
                  value={teamMember.bio}
                  placeholder="Bio"
                />
              </div>
            </React.Fragment>
          ))}
        </div>
        <div className="edit-team__buttons">
          <Button
            type="submit"
            text="Add Team Member"
            onClick={addTeamMember}
            alternate={true}
          />
          <Button
            disabled={Boolean(error)}
            type="submit"
            text="Update Team"
            onClick={updateTeam}
          />
        </div>
        {error && <p className="error-message">{error.message}</p>}
      </div>
    )
  );
};

export default EditTeam;
