import React from "react";
import "../styles/contact.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { connect } from "react-redux";
import { setCurrentTab } from "../actions/setCurrentTab";
import VisibilitySensor from "react-visibility-sensor";
import Button from "../components/Button";
import Input from "../components/Input";
import { Link } from 'react-router-dom';

AOS.init({
  once: false,
  mirror: true
});

const Contact = props => {
  const onVisibilityChange = isVisible => {
    return isVisible ? props.setCurrentTab("contact") : null;
  };

  return (
    <VisibilitySensor onChange={onVisibilityChange}>
      <div style={{ overflow: "hidden" }}>
        <div className="contact">
          <div className="contact__form" data-aos="fade-right">
            <h1 className="title">Get in Touch</h1>
            <form
              action="https://formspree.io/theatredancestudios@gmail.com"
              method="POST"
            >
              <Input name="name" placeholder="Name" type="text" required />
              <Input name="email" placeholder="Email" type="email" required />
              <Input
                name="message"
                placeholder="Message"
                type="textarea"
                required
              />
              <Button type="submit" text="Send" />
            </form>
          </div>
          <img alt="logo" src={require("../imgs/logo.svg")} />
        </div>
        <footer>
          <p>
            Copyright 
            <img
              alt="copyright"
              className="copyright-image"
              src={require("../imgs/copyrightImg.png")}
              style={{margin: '0 0.1rem'}}
            />
             2021 All Rights Reserved By Theatre Dance Studios Bolton 2021
          </p>
          <Link style={{alignSelf: 'center'}} to='/privacy-policy'><p>Privacy Policy</p></Link>
        </footer>
      </div>
    </VisibilitySensor>
  );
};

const mapStateToProps = state => {
  return {
    currentTab: state.currentTabReducer.currentTab
  };
};

const mapDispatchToProps = dispatch => ({
  setCurrentTab: title => {
    dispatch(setCurrentTab(title));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contact);
