import React from "react";
import "../styles/contactIcons.scss";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init({ anchorPlacement: "center-top" });

const ContactIcons = () => {
  return (
    <div className="contact-icons">
      <a
        href="https://www.facebook.com/tdsbolton/"
        className="contact-icons__icon"
        target="blank"
      >
        <img alt="facebook icon" src={require("../imgs/facebookIcon.svg")} />
      </a>
      <a
        href="https://twitter.com/tdsbolton?lang=en"
        className="contact-icons__icon"
        target="blank"
      >
        <img alt="twitter icon" src={require("../imgs/twitterIcon.svg")} />
      </a>
      <a
        href="https://www.instagram.com/tdsbolton/?hl=en"
        className="contact-icons__icon"
        target="blank"
      >
        <img alt="instagram icon" src={require("../imgs/instagramIcon.svg")} />
      </a>
      <a
        href="tel:+441204867022"
        className="contact-icons__icon contact-icons__icon__phone"
      >
        <img alt="phone icon" src={require("../imgs/phoneIcon.svg")} />
      </a>
      <a
        href="mailto:theatredancestudios@gmail.com"
        className="contact-icons__icon"
      >
        <img alt="email icon" src={require("../imgs/emailIcon.svg")} />
      </a>
    </div>
  );
};

export default ContactIcons;
