import React, { useState, useEffect, useCallback } from 'react';
import '../styles/navbar.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { useSelector, useDispatch } from 'react-redux';
import Button from './Button';
import { Link } from 'react-router-dom';
import { signOut } from '../actions/auth';

AOS.init({
  duration: 800
});


const Navbar = () => {
  const isSignedIn = useSelector(state => state.authReducer.isSignedIn);
  const User = useSelector(state => state.authReducer.user);
  const currentTab = useSelector(state => state.currentTabReducer.currentTab);
  const dispatch = useDispatch();
  const [color, changeColor] = useState(null);
  const [logoSrc, changeLogoSrc] = useState('logo');
  const [addUserIcon, changeAddUserIcon] = useState('addUserIcon');
  const [menuOpen, handleOpenMenu] = useState(false);

  const onSignOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => dispatch(signOut()));
  };

  const menuToggle = () => {
    if (window.matchMedia('(min-width: 1025px)').matches) {
      handleOpenMenu(true);
      changeAddUserIcon('addUserIcon')

    } else {
      handleOpenMenu(false);
      changeAddUserIcon('addUserIconAlt')
    }
  };

  const handleMenuClick = () => {
    menuOpen ? handleOpenMenu(false) : handleOpenMenu(true);
  };

  const toggleIconColor = () => {
    if (window.matchMedia('(max-width: 700px)').matches) {
      changeLogoSrc('logo-sm');
    } else {
      changeLogoSrc('logo');
    }
  };

  const setNavbarBackground = useCallback(() => {
    if (currentTab && currentTab !== 'header') return changeColor('white')
    if (window.matchMedia('(min-width: 960px)').matches) {
      window.scrollY > 20 ? changeColor('white') : changeColor('null');
    } else {
      changeColor('white');
    }
  }, [currentTab])

  useEffect(() => window.addEventListener('resize', menuToggle), [])
  useEffect(() => window.addEventListener('resize', toggleIconColor), [])
  useEffect(() => window.addEventListener('scroll', setNavbarBackground), [setNavbarBackground])

  useEffect(() => {
    menuToggle();
    toggleIconColor();
    setNavbarBackground();

  }, [setNavbarBackground]);

  return (
    <div
      data-aos="fade-in"
      className="navbar"
      style={{ backgroundColor: color }}
    >
      <a href="#header">
        <img
          className="navbar__logo"
          alt="logo"
          src={require(`../imgs/${logoSrc}.svg`)}
        />
      </a>
      <nav className="navbar__menu" style={menuOpen ? null : { width: 0 }}>
        <ul className="navbar__menu__list">
          <li
            className={`navbar__menu__item${
              currentTab === 'events' ? ' navbar__current-section' : ''
              }`}
            onClick={() =>
              window.matchMedia('(max-width: 1025px)').matches
                ? handleOpenMenu(false)
                : null
            }
          >
            <a href="#events" style={menuOpen ? null : { opacity: 0 }}>
              Events
            </a>
          </li>
          <li
            className={`navbar__menu__item${
              currentTab === 'about' ? ' navbar__current-section' : ''
              }`}
            onClick={() =>
              window.matchMedia('(max-width: 1025px)').matches
                ? handleOpenMenu(false)
                : null
            }
          >
            <a href="#about" style={menuOpen ? null : { opacity: 0 }}>
              About Us
            </a>
          </li>
          <li
            className={`navbar__menu__item${
              currentTab === 'team' ? ' navbar__current-section' : ''
              }`}
            onClick={() =>
              window.matchMedia('(max-width: 1025px)').matches
                ? handleOpenMenu(false)
                : null
            }
          >
            <a href="#team" style={menuOpen ? null : { opacity: 0 }}>
              Our Team
            </a>
          </li>
          <li
            className={`navbar__menu__item${
              currentTab === 'studio' ? ' navbar__current-section' : ''
              }`}
            onClick={() =>
              window.matchMedia('(max-width: 1025px)').matches
                ? handleOpenMenu(false)
                : null
            }
          >
            <a href="#studio" style={menuOpen ? null : { opacity: 0 }}>
              Studio
            </a>
          </li>
          <li
            className={`navbar__menu__item${
              currentTab === 'workshop' ? ' navbar__current-section' : ''
              }`}
            onClick={() =>
              window.matchMedia('(max-width: 1025px)').matches
                ? handleOpenMenu(false)
                : null
            }
          >
            <a href="#workshop" style={menuOpen ? null : { opacity: 0 }}>
              What We Offer
            </a>
          </li>
          <li
            className={`navbar__menu__item${
              currentTab === 'shows' ? ' navbar__current-section' : ''
              }`}
            onClick={() =>
              window.matchMedia('(max-width: 1025px)').matches
                ? handleOpenMenu(false)
                : null
            }
          >
            <a href="#shows" style={menuOpen ? null : { opacity: 0 }}>
              Shows
            </a>
          </li>
          <li
            className={`navbar__menu__item${
              currentTab === 'awards' ? ' navbar__current-section' : ''
              }`}
            onClick={() =>
              window.matchMedia('(max-width: 1025px)').matches
                ? handleOpenMenu(false)
                : null
            }
          >
            <a href="#awards" style={menuOpen ? null : { opacity: 0 }}>
              Awards
            </a>
          </li>
          <li
            className={`navbar__menu__item${
              currentTab === 'timetable' ? ' navbar__current-section' : ''
              }`}
            onClick={() =>
              window.matchMedia('(max-width: 1025px)').matches
                ? handleOpenMenu(false)
                : null
            }
          >
            <a href="#timetable" style={menuOpen ? null : { opacity: 0 }}>
              Timetable
            </a>
          </li>
          <li
            className={`navbar__menu__item${
              currentTab === 'contact' ? ' navbar__current-section' : ''
              }`}
            onClick={() =>
              window.matchMedia('(max-width: 1025px)').matches
                ? handleOpenMenu(false)
                : null
            }
          >
            <a href="#contact" style={menuOpen ? null : { opacity: 0 }}>
              Contact Us
            </a>
          </li>
        </ul>
        <div
          className="navbar__auth"
          style={menuOpen ? null : { opacity: 0 }}
        >
          {isSignedIn ? (

            <div className='navbar__auth--signed-in'>
              <div className='navbar__auth--signed-in__inner'>
                <span>{User.name && User.name.split(' ').slice(0, -1)}</span>
                <Link to='/add-user'>
                  <img className='add-user-icon' alt='add user' src={require(`../imgs/${addUserIcon}.svg`)} />
                </Link>
              </div>
              <Button text="Sign Out" onClick={onSignOut} />
            </div>
          ) : (
              <Link to="/sign-in">
                <Button text="Admin Sign In" />
              </Link>
            )}
        </div>
      </nav>
      <div
        className={`navbar__menu-icon ${menuOpen ? 'open-menu' : null}`}
        onClick={handleMenuClick}
      >
        <div
          className={`navbar__menu-icon__line line line-1 ${
            menuOpen ? 'open-menu__1' : 'close-menu__1'
            }`}
        ></div>
        <div
          className={`navbar__menu-icon__line line line-2 ${
            menuOpen ? 'open-menu__2' : 'close-menu__2'
            }`}
        ></div>
        <div
          className={`navbar__menu-icon__line line line-3 ${
            menuOpen ? 'open-menu__3' : 'close-menu__3'
            }`}
        ></div>
      </div>
    </div>
  );
};

export default Navbar;
