import React from 'react';
import Input from './Input';
import Button from './Button';
import "../styles/addImage.scss";

const getLabel = (label, value) => {
  const newLabel = `${label}: ${value ? `${value}` : 'None'}`;
  if (newLabel.length > 20) return `${newLabel.slice(0, 20)}...`;

  return newLabel
}

const AddImage = ({handleOnChange, label, value}) => (
  <div className='add-image__container'>
    <Button text={value ? 'Update Image' : 'New Image'} className='add-image__button' />
    <p className='add-image__value'>
      {getLabel(label, value)}
    </p>
    <div className='add-image__input'>
      <Input
        onChange={handleOnChange}
        type="file"
        style={{width: '100%', height: '100%', cursor: 'pointer'}}
      />
    </div>
  </div>
);

export default AddImage