import React, { useState } from "react";
import Input from "../components/Input";
import Button from "../components/Button";
import * as firebase from "firebase/app";
import "firebase/auth";
import { connect, useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { signIn, signOut } from "../actions/auth";
import "../styles/signIn.scss";
import LoadingSpinner from "../components/LoadingSpinner";

const SignIn = () => {
  const isSignedIn = useSelector(state => state.authReducer.isSignedIn);
  const dispatch = useDispatch();
  const [email, changeEmail] = useState("");
  const [password, changePassword] = useState("");
  const [error, showError] = useState(false);
  const [redirect, handleRedirect] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSignIn = e => {
    e.preventDefault();
    setLoading(true);

    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then(() => {
            dispatch(signIn(firebase.auth().currentUser.uid));
          })
          .finally(() => {
            setLoading(false);
            handleRedirect(true);
          })
          .catch(error => {
            showError(error);
          });
      });
  };

  const onSignOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => dispatch(signOut()))
      .catch(error => {
        showError(error);
      });
  };

  return (
    <div className="sign-in">
      {loading && <LoadingSpinner />}
      {redirect && <Redirect to="/" />}
      {isSignedIn ? (
        <div className="sign-in__form sign-in__form--signed-in">
          <h1>You are already signed in</h1>
          <Button text="Sign Out" onClick={onSignOut} />
        </div>
      ) : (
        <form className="sign-in__form" onSubmit={onSignIn}>
          <h1 className="title">Sign In</h1>
          <Input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={e => changeEmail(e.target.value)}
          />
          <Input
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={e => changePassword(e.target.value)}
          />
          <Button text="Sign In" />
          {error && <p className="error-message">{error.message}</p>}
        </form>
      )}
      <img alt="logo" src={require("../imgs/logo.svg")} />
    </div>
  );
};

export default connect(
  null,
  { signIn }
)(SignIn);
