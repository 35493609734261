import React from 'react';
import TeamMemberMobile from '../components/TeamMemberMobile';
import '../styles/team.scss';

const Team = ({ team }) => (
  <div className="team__mobile">
    {team.map((teamMember, i) => (
      <TeamMemberMobile
        teamMember={teamMember}
        description={teamMember.description}
        key={i}
      />
    ))}
  </div>
);


export default Team;
