import React, { useState, useEffect } from "react";
import "../styles/team.scss";
import TeamList from "./TeamList";
import TeamListMobile from "./TeamListMobile";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentTab } from "../actions/setCurrentTab";
import VisibilitySensor from "react-visibility-sensor";
import { Link } from "react-router-dom";
import firebase from "../Config/firebaseConfig";
import "firebase/firestore";
import "firebase/storage";

AOS.init({
  once: false,
  mirror: true,
  anchorPlacement: "top-bottom"
});

const Team = () => {
  const isSignedIn = useSelector(state => state.authReducer.isSignedIn);
  const dispatch = useDispatch();
  const [team, setTeam] = useState([]);

  const onVisibilityChange = isVisible => {
    return isVisible ? dispatch(setCurrentTab("team")) : null;
  };

  useEffect(() => {
    const db = firebase.firestore();

    db.collection("team")
      .get()
      .then(docs => {
        let arr = [];

        docs.forEach(doc => {
          arr = [...arr, doc.data()];
        });
        setTeam(arr);
      });
  }, []);

  return (
    <VisibilitySensor
      onChange={onVisibilityChange}
      partialVisibility
      offset={{ top: window.innerHeight / 2, bottom: window.innerHeight / 2 }}
      intervalCheck
    >
      <div className="team">
        {isSignedIn && (
          <Link to="/edit/team" className="edit">
            <span>Edit Team</span>
            <img alt="edit" src={require("../imgs/editIcon.svg")} />
          </Link>
        )}
        <h1 data-aos="fade" className="title">
          Our Team
        </h1>
        <TeamList team={team} />
        <TeamListMobile team={team} />
      </div>
    </VisibilitySensor>
  );
};

export default Team;
