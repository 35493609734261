import React from 'react';
import '../styles/button.scss';

const Button = ({ text, type, onClick = null, disabled, alternate = false, style, className }) => (
  <button disabled={disabled} className={`button button--${alternate ? 'alternate' : 'default'} ${className || ''}`} type={type} onClick={onClick} style={style}>
    {text}
  </button>
);

export default Button;
