import React, { useState, useEffect } from "react";
import "../styles/exams.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTab } from "../actions/setCurrentTab";
import VisibilitySensor from "react-visibility-sensor";
import { Link } from "react-router-dom";
import firebase from "../Config/firebaseConfig";
import "firebase/firestore";
import "firebase/storage";

AOS.init({
  once: false,
  mirror: true,
  anchorPlacement: "top-bottom"
});

const storage = firebase.storage();
const storageRef = storage.ref();

const Exams = () => {
  const dispatch = useDispatch();
  const isSignedIn = useSelector(state => state.authReducer.isSignedIn);
  const [data, setData] = useState(null);
  const [image, setImage] = useState([]);
  const [examBoards, setExamBoards] = useState([]);

  const onVisibilityChange = isVisible => {
    return isVisible ? dispatch(setCurrentTab("workshop")) : null;
  };

  useEffect(() => {
    const db = firebase.firestore();

    db.collection("exams")
      .doc("exams")
      .get()
      .then(doc => {
        setData(doc.data());

        storageRef
          .child(`exams/${doc.data().image}`)
          .getDownloadURL()
          .then(url => {
            setImage(url);
          });

        doc.data().examBoards.map(image =>
          storageRef
            .child(`exams/${image}`)
            .getDownloadURL()
            .then(url => {
              setExamBoards(prevState => [...prevState, url]);
            })
        );
      });

    return () => {};
  }, []);

  return (
    data && (
      <VisibilitySensor
        onChange={onVisibilityChange}
        partialVisibility
        offset={{ top: window.innerHeight / 2, bottom: window.innerHeight / 2 }}
        intervalCheck
      >
        <div className="exams__container">
          {isSignedIn && (
            <Link to="/edit/exams" className="edit">
              <span>Edit Exams</span>
              <img alt="edit" src={require("../imgs/editIcon.svg")} />
            </Link>
          )}
          <div className="exams">
            <div data-aos="fade-up" className="exams__content">
              <h1 data-aos="fade-up" className="title">
                {data.title}
              </h1>
              <p>{data.content}</p>
              <div className="exams__boards">
                {examBoards &&
                  examBoards.map((exam, i) => (
                    <img key={i + 1} alt="istd" src={exam} />
                  ))}
              </div>
            </div>
            <div
              className="exams__image"
              data-aos="fade-up"
              data-aos-mirror="true"
            >
              <img src={image} alt="exam" />
            </div>
          </div>
        </div>
      </VisibilitySensor>
    )
  );
};

export default Exams;
