import React, { useState, useEffect } from "react";
import Input from "../components/Input";
import Button from "../components/Button";
import "../styles/editExams.scss";
import "firebase/firestore";
import "firebase/storage";
import firebase from "../Config/firebaseConfig";
import LoadingSpinner from "../components/LoadingSpinner";
import AddImage from "../components/AddImage";

const db = firebase.firestore();
const storage = firebase.storage();
const storageRef = storage.ref();

const EditExams = ({ history }) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [images, setImages] = useState(new Array(5));
  const [loading, setLoading] = useState(true);

  const updateExams = () => {
    const uploaded = [];

    const uploadImages = () => {
      if (images.length > 0) {
        images.forEach(image => {
          setLoading(true);
          if (image) uploaded.push(image);
          image &&
            storageRef
              .child(`/exams/${image.name}`)
              .put(image)
              .on("state_changed", snapshot => {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                if (progress === 100) uploaded.pop();
                if (progress === 100 && uploaded.length === 0) {
                  updateDb();
                  setLoading(false);
                  history.push("/");
                }
              });
        });
      } else {
        updateDb();
        setLoading(false);
        history.push("/");
      }
    };

    const updateDb = () =>
      db
        .collection("exams")
        .doc("exams")
        .update(data)
        .catch(error => {
          setError(error);
        });

    uploadImages();
  };

  const handleOnChange = (e, field, num = "") => {
    let newData = { ...data };
    let imageArr = [...images];

    if (field.includes("board") && e.target.files) {
      newData.examBoards[num] = e.target.files[0].name;
      imageArr[num + 1] = e.target.files[0];
      setImages(imageArr);
    } else if (field.includes("images") && e.target.files) {
      newData.image = e.target.files[0].name;
      imageArr[num] = e.target.files[0];
      setImages(imageArr);
    } else {
      newData[field] = e.target.value;
    }

    setData(newData);
  };

  const addExamBoard = () => {
    const newData = { ...data };
    newData.examBoards = [...data.examBoards, ""];
    setData(newData);
  };

  const removeExamBoard = num => {
    const newData = { ...data };
    let newArr = [...data.examBoards];

    newData.examBoards = newArr.splice(num, 1);
    setData(newData);
  };

  useEffect(() => {
    db.collection("exams")
      .doc("exams")
      .get()
      .then(doc => {
        setData(doc.data());
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    data && (
      <div className="edit-exams">
        {loading && <LoadingSpinner />}
        <h2>Edit Exams</h2>
        <div className="edit-exams__container">
          <h3>Information</h3>
          <div className="edit-exams__section">
            <Input
              onChange={e => handleOnChange(e, "title")}
              value={data.title}
              placeholder="Title"
            />
            <Input
              type="textarea"
              maxLength="500"
              onChange={e => handleOnChange(e, "content")}
              value={data.content}
              placeholder="Content"
            />
          </div>
          <h3>Image</h3>
          <div className="edit-exams__section">
            <AddImage
            handleOnChange={e => handleOnChange(e, "images", 0)}
            label="Image"
            value={data.image}
          />
          </div>
          <h3>Exam Boards</h3>
          <div className="edit-exams__section">
            {data.examBoards &&
              data.examBoards.map((exam, i) => (
                <div className="edit-exams__board" key={i + 1}>
                  <AddImage
                    handleOnChange={e => handleOnChange(e, "board", i)}
                    label="Image"
                    value={exam}
                  />
                  <img
                    src={require("../imgs/deleteIcon.svg")}
                    className="delete-icon"
                    alt="delete"
                    onClick={() => removeExamBoard(i)}
                  />
                </div>
              ))}
          </div>
          <Button
            type="submit"
            text="Add Exam Board"
            onClick={addExamBoard}
            alternate={true}
          />
        </div>
        <div className="edit-exams__buttons">
          <Button
            disabled={Boolean(error)}
            type="submit"
            text="Update exams"
            onClick={updateExams}
          />
          {error && <p className="error-message">{error.message}</p>}
        </div>
      </div>
    )
  );
};

export default EditExams;
