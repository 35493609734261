import React, { useState, useEffect } from "react";
import "../styles/timetable.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTab } from "../actions/setCurrentTab";
import VisibilitySensor from "react-visibility-sensor";
import Select from "react-select";
import firebase from "../Config/firebaseConfig";
import "firebase/firestore";
import { Link } from "react-router-dom";

AOS.init({
  once: false,
  mirror: true,
  anchorPlacement: "top-top"
});

const Timetable = () => {
  const isSignedIn = useSelector(state => state.authReducer.isSignedIn);
  const [selectedDay, setSelectedDay] = useState({
    value: "Monday",
    label: "Monday"
  });
  const [selectedStudio, setSelectedStudio] = useState({
    value: "Studio 1",
    label: "Studio 1"
  });
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const days = [
    { value: "Monday", label: "Monday" },
    { value: "Tuesday", label: "Tuesday" },
    { value: "Wednesday", label: "Wednesday" },
    { value: "Thursday", label: "Thursday" },
    { value: "Friday", label: "Friday" },
    { value: "Saturday", label: "Saturday" },
    { value: "Sunday", label: "Sunday" }
  ];
  const studios = [
    { value: "Studio 1", label: "Studio 1" },
    { value: "Studio 2", label: "Studio 2" },
    { value: "Studio 3", label: "Studio 3" }
  ];

  useEffect(() => {
    const db = firebase.firestore();

    db.collection("timetable")
      .doc(selectedDay.value)
      .get()
      .then(doc => {
        doc && setData(doc.data().rows);
      });

    return () => {};
  }, [selectedDay]);

  const onVisibilityChange = isVisible =>
    isVisible ? dispatch(setCurrentTab("timetable")) : null;

  return (
    <VisibilitySensor
      onChange={onVisibilityChange}
      partialVisibility
      offset={{ top: window.innerHeight / 2, bottom: window.innerHeight / 2 }}
      intervalCheck
    >
      <div className="timetable">
        {isSignedIn && (
          <Link to="/edit/timetable" className="edit">
            <span>Edit Timetable</span>
            <img alt="edit" src={require("../imgs/editIcon.svg")} />
          </Link>
        )}
        <h1 data-aos="fade-up" className="title">
          Timetable
        </h1>
        <div className="timetable__content">
          <nav data-aos="fade-right" className="days">
            <ul>
              {days.map(day =>
                day.value === selectedDay.value ? (
                  <li key={day.value} className="days--selected">
                    <h4>{day.value}</h4>
                  </li>
                ) : (
                  <li
                    key={day.value}
                    onClick={() =>
                      setSelectedDay({ value: day.value, label: day.value })
                    }
                  >
                    <h4>{day.value}</h4>
                  </li>
                )
              )}
            </ul>
          </nav>
          <div
            data-aos="fade-left"
            data-aos-delay="200"
            className="table__container"
          >
            {data.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    <td className="column__times"></td>
                    <td>Studio 1</td>
                    <td>Studio 2</td>
                    <td>Studio 3</td>
                  </tr>
                </thead>
                <tbody>
                  {data.map(row => {
                    return (
                      <tr key={row.time}>
                        <td className="row__time">{row.time}</td>
                        <td>{row.studio1}</td>
                        <td>{row.studio2}</td>
                        <td>{row.studio3}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <h2 className="no-classes">No Classes Today</h2>
            )}
          </div>
        </div>
        <div className="timetable__content__mobile">
          <div className="timetable__options__mobile" data-aos="fade-up">
            <Select
              value={selectedDay}
              onChange={value => setSelectedDay(value)}
              options={days}
              className="react-select-container"
              classNamePrefix="react-select"
              isSearchable={false}
            />
            <Select
              value={selectedStudio}
              onChange={value => setSelectedStudio(value)}
              options={studios}
              className="react-select-container"
              classNamePrefix="react-select"
              isSearchable={false}
            />
          </div>
          <div className="timetable__mobile">
            <table data-aos="fade-up">
              <tbody>
                {data &&
                  data.map(row => {
                    if (selectedStudio.value === "Studio 1") {
                      return (
                        <tr key={row.time}>
                          <td className="row__time">{row.time}</td>
                          <td>{row.studio1}</td>
                        </tr>
                      );
                    }
                    if (selectedStudio.value === "Studio 2") {
                      return (
                        <tr key={row.time}>
                          <td className="row__time">{row.time}</td>
                          <td>{row.studio2}</td>
                        </tr>
                      );
                    }
                    return (
                      <tr key={row.time}>
                        <td className="row__time">{row.time}</td>
                        <td>{row.studio3}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {!data && <h2 className="no-classes">No Classes Today</h2>}
          </div>
        </div>
      </div>
    </VisibilitySensor>
  );
};

export default Timetable;
