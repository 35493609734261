import React, { useState, useEffect } from "react";
import "../styles/header.scss";
import ContactIcons from "../components/ContactIcons";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentTab } from "../actions/setCurrentTab";
import VisibilitySensor from "react-visibility-sensor";
import Fade from "../components/Fade";
import { Link } from "react-router-dom";
import firebase from "../Config/firebaseConfig";
import "firebase/firestore";
import "firebase/storage";

AOS.init();

const storage = firebase.storage();
const storageRef = storage.ref();

const Header = () => {
  const dispatch = useDispatch();
  const isSignedIn = useSelector(state => state.authReducer.isSignedIn);
  const [data, setData] = useState(null);
  const [images, setImages] = useState([]);

  const onVisibilityChange = isVisible => {
    return isVisible ? dispatch(setCurrentTab("header")) : null;
  };

  useEffect(() => {
    const db = firebase.firestore();

    db.collection("header")
      .doc("header")
      .get()
      .then(doc => {
        setData(doc.data());

        doc.data().images.map(image =>
          storageRef
            .child(`header/${image}`)
            .getDownloadURL()
            .then(url => {
              setImages(prevState => [...prevState, url]);
            })
        );
      });

    return () => {};
  }, []);

  return (
    <VisibilitySensor
      onChange={onVisibilityChange}
      partialVisibility
      offset={{ top: window.innerHeight / 2, bottom: window.innerHeight / 2 }}
      intervalCheck
    >
      <div data-aos="fade" className="header">
        {data && isSignedIn && (
          <Link to="/edit/header" className="edit" data-aos="fade-right">
            <span>Edit Header</span>
            <img alt="edit" src={require("../imgs/editIcon.svg")} />
          </Link>
        )}
        {data && (
          <div className="header__inner">
            <div data-aos="fade-right" className="header__section">
              <h2>{data.intro}</h2>
              <h1>{data.title}</h1>
              <h2>{data.subtitle}</h2>
            </div>
            <div data-aos="fade-left" className="header__section">
              <img alt="logo" src={require("../imgs/logo.svg")} />
            </div>
          </div>
        )}
        <div
          className="header__sub"
          data-aos="fade"
          data-aos-anchor-placement="bottom"
        >
          {data && <ContactIcons />}

          <h3>{data && data.address}</h3>
          <Fade />
        </div>
        <ul className="header__slideshow slideshow">
          {images &&
            images.map((image, i) => (
              <li key={i + 1} style={{ backgroundImage: `url(${image})` }} />
            ))}
        </ul>
      </div>
    </VisibilitySensor>
  );
};

export default Header;
