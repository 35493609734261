import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Redirect, Switch, withRouter } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import Home from './views/Home';
import SignIn from './views/SignIn';
import AddUser from './views/AddUser';
import EditEvents from './views/EditEvents';
import EditHeader from './views/EditHeader';
import EditAbout from './views/EditAbout';
import EditTeam from './views/EditTeam';
import EditStudio from './views/EditStudio';
import EditWorkshop from './views/EditWorkshop';
import EditSinging from './views/EditSinging';
import EditExams from './views/EditExams';
import EditShows from './views/EditShows';
import EditAwards from './views/EditAwards';
import EditTimetable from './views/EditTimetable';
import { signIn } from './actions/auth';
import firebase from './Config/firebaseConfig';
import 'firebase/auth';
import 'firebase/firestore';
import PrivacyPolicy from './views/PrivacyPolicy';

const db = firebase.firestore();

const App = () => {
  const User = useSelector(state => state.authReducer.user);
  const dispatch = useDispatch();
  const [app, renderApp] = useState(false);

  const PrivateRoute = ({ component: Component }) => (
    <Route
      render={() =>
        firebase.auth().currentUser ? <Component /> : <Redirect to={{ pathname: '/' }} />
      }
    />
  );

  useEffect(() => {
    const setCurrentUser = user => dispatch(signIn(user))

    const getCurrentUser = (user) => (
      db.collection('users').get().then(docs => docs.forEach(doc => {
        if (doc.data().id === user.uid)
          return setCurrentUser(doc.data())
      }))
    )

    const checkAuthState = () => {
      !User && firebase.auth().onAuthStateChanged(user => {
        user && getCurrentUser(user)
        renderApp(true)
      })
    }

    checkAuthState()
  }, [User, dispatch])


  return app && (
    <div>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/sign-in" exact component={SignIn} />
          <Route path="/add-user" exact component={AddUser} />
          <Route path="/privacy-policy" exact component={PrivacyPolicy} />
          <PrivateRoute path="/edit/events" exact component={withRouter(EditEvents)} />
          <PrivateRoute path="/edit/header" exact component={withRouter(EditHeader)} />
          <PrivateRoute path="/edit/about" exact component={withRouter(EditAbout)} />
          <PrivateRoute path="/edit/team" exact component={withRouter(EditTeam)} />
          <PrivateRoute path="/edit/studio" exact component={withRouter(EditStudio)} />
          <PrivateRoute path="/edit/workshop" exact component={withRouter(EditWorkshop)} />
          <PrivateRoute path="/edit/singing" exact component={withRouter(EditSinging)} />
          <PrivateRoute path="/edit/exams" exact component={withRouter(EditExams)} />
          <PrivateRoute path="/edit/shows" exact component={withRouter(EditShows)} />
          <PrivateRoute path="/edit/awards" exact component={withRouter(EditAwards)} />
          <PrivateRoute path="/edit/timetable" exact component={withRouter(EditTimetable)} />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default connect(
  null,
  { signIn }
)(App);
