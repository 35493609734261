import React, { useState, useEffect } from 'react';
import '../styles/team.scss';
import 'firebase/firestore';
import 'firebase/storage';
import firebase from '../Config/firebaseConfig';

const storage = firebase.storage();
const storageRef = storage.ref();
const profileIcon = require('../imgs/icon-profile.svg')

const TeamMember = ({ teamMember, className, onClick }) => {
  const [source, setSource] = useState(null)

  useEffect(() => {
    const getSource = async () => storageRef.child(`team/${teamMember.image}`).getDownloadURL().then((url) => {
      setSource(url);
    })

    if(teamMember.image) getSource()
    else setSource(profileIcon)
  }, [teamMember.image]);

  return (
    <div
      onClick={() => onClick()}
      className={`team-member${className ? ` team-member__${className}` : ''}`}
    >
      <div className={`team-member__image${teamMember.image ? '' : ' team-member__image--default'}`}>
        <img alt={`${teamMember.name}`} src={source} />
      </div>
      <h2>{teamMember.name}</h2>
    </div>
  );
};

export default TeamMember;
