import React, { useState, useEffect } from 'react';
import '../styles/awards.scss';
import Award from '../components/Award';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentTab } from '../actions/setCurrentTab';
import VisibilitySensor from 'react-visibility-sensor';
import { Link } from 'react-router-dom';
import firebase from '../Config/firebaseConfig';

AOS.init({
  once: false,
  mirror: true,
  anchorPlacement: 'top-top'
});

const Awards = () => {
  const isSignedIn = useSelector(state => state.authReducer.isSignedIn);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const onVisibilityChange = isVisible => isVisible ? dispatch(setCurrentTab('awards')) : null;

  useEffect(() => {
    const db = firebase.firestore();
    let arr = []

    db.collection('awards').get().then(docs => {

      docs.forEach(doc => {
        arr = [...arr, doc.data()]
        setData(arr);
      })

      return () => { }
    })
  }, [])

  return data && (
    <VisibilitySensor
      onChange={onVisibilityChange}
      partialVisibility
      offset={{ top: window.innerHeight / 2, bottom: window.innerHeight / 2 }}
      intervalCheck
    >
      <div
        style={{
          width: '100vw',
          background: 'white',
          marginBottom: '20px'
        }}
      >
        <div data-aos="zoom-in" className="awards">
          <h1 data-aos="fade" className="title">
            Awards
            </h1>
          {isSignedIn && <Link to='/edit/awards' className='edit'>
            <span>Edit Awards</span>
            <img alt='edit' src={require('../imgs/editIcon.svg')} />
          </Link>}
          <div className="awards__list">
            {data.map((award, i) => {
              return (
                <Award
                  award={award}
                  side={i % 2 === 0 ? 'right' : 'left'}
                  key={i}
                />
              );
            })}
          </div>
        </div>
      </div>
    </VisibilitySensor >
  );
}

export default Awards;
