import React from 'react';
import '../styles/input.scss';

const Input = ({ type = 'text', placeholder, required = false, name = 'input', value, onChange, maxLength, style }) =>
  type === 'textarea' ? (
    <textarea
      className="input input--textarea"
      type="text"
      placeholder={placeholder}
      required={required}
      name={name}
      value={value}
      onChange={onChange}
      maxLength={maxLength}
      style={style}
    />
  ) : (
      <input
        className="input"
        type={type}
        placeholder={placeholder}
        required={required}
        name={name}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        style={style}
      />
    );

export default Input;
