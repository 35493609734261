import React, { useState, useEffect } from "react";
import Input from "../components/Input";
import Button from "../components/Button";
import "../styles/editEvents.scss";
import "firebase/firestore";
import "firebase/storage";
import firebase from "../Config/firebaseConfig";
import LoadingSpinner from "../components/LoadingSpinner";
import AddImage from "../components/AddImage";

const db = firebase.firestore();
const storage = firebase.storage();
const storageRef = storage.ref();

const UpdateEvents = ({ history }) => {
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(null);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleOnChange = (num, e, field) => {
    let newEvents = [...events];
    let event = { ...events[num] };
    let imageArr = [...images];

    if (field === "name") event.name = e.target.value;
    if (field === "date") {
      setError(
        e.target.value.length !== 6
          ? { message: "Date must follow this example: Sep 09" }
          : null
      );
      event.date = e.target.value;
    }
    if (field === "image") {
      event.image = e.target.files[0].name;
      imageArr[num] = e.target.files[0];
      setImages(imageArr);
    }
    if (field === "location") event.location = e.target.value;
    if (field === "time") event.time = e.target.value;
    if (field === "price") event.price = e.target.value;

    newEvents[num] = event;
    setEvents(newEvents);
  };

  const updateEvents = async () => {
    const uploaded = [];

    const uploadImages = () => {
      if (images.length > 0) {
        images.forEach(image => {
          setLoading(true);
          if (image) uploaded.push(image);
          image &&
            storageRef
              .child(`/events/${image.name}`)
              .put(image)
              .on("state_changed", snapshot => {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                if (progress === 100) uploaded.pop();
                if (progress === 100 && uploaded.length === 0) {
                  updateDb();
                  deleteEventInfo();
                  setLoading(false);
                  history.push("/");
                }
              });
        });
      } else {
        updateDb();
        deleteEventInfo();
        setLoading(false);
        history.push("/");
      }
    };

    const updateDb = () => {
      db.collection("events")
        .doc("Event 1")
        .update(events[0]);
      db.collection("events")
        .doc(`Event 2`)
        .update(events[1]);
      db.collection("events")
        .doc("Event 3")
        .update(events[2])
        .then(() => history.push("/"))
        .catch(error => {
          setError(error);
        });
    };

    uploadImages();
  };

  const deleteEventInfo = num => {
    let newEvents = [...events];
    let event = { ...events[num] };

    event = {
      name: "",
      date: "",
      location: "",
      time: "",
      price: "",
      image: ""
    };

    newEvents[num] = event;
    setEvents(newEvents);
  };

  const renderForm = num => {
    return (
      <div key={num} className="update-events__list__item">
        <h3>Event {num + 1}</h3>
        <img
          src={require("../imgs/deleteIcon.svg")}
          className="delete-icon"
          alt="delete"
          onClick={() => deleteEventInfo(num)}
        />
        <Input
          value={events[num].name}
          onChange={e => handleOnChange(num, e, "name")}
          placeholder="Event Name"
        />
        <Input
          value={events[num].date}
          onChange={e => handleOnChange(num, e, "date")}
          placeholder="Date (must follow this example: Mar 01)"
        />
        <Input
          value={events[num].location}
          onChange={e => handleOnChange(num, e, "location")}
          placeholder="Location"
        />
        <Input
          value={events[num].time}
          onChange={e => handleOnChange(num, e, "time")}
          placeholder="Time"
        />
        <Input
          value={events[num].price}
          onChange={e => handleOnChange(num, e, "price")}
          placeholder="Price"
        />
        <AddImage
            handleOnChange={e => handleOnChange(num, e, "image")}
            label="Image"
            value={events[num].image}
          />
      </div>
    );
  };

  useEffect(() => {
    db.collection("events")
      .get()
      .then(docs => {
        let arr = [];

        docs.forEach(doc => {
          arr = [...arr, doc.data()];
        });
        setEvents(arr);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <div className="update-events">
      {loading && <LoadingSpinner />}
      <div className="update-events__form">
        <h1 className="title">Update Events</h1>
        <div className="update-events__list">
          {events.map((event, i) => event && renderForm(i))}
        </div>
        <Button
          disabled={Boolean(error)}
          type="submit"
          text="Update Events"
          onClick={updateEvents}
        />
        {error && <p className="error-message">{error.message}</p>}
      </div>
    </div>
  );
};

export default UpdateEvents;
