import React, { useState, useEffect } from "react";
import Input from "../components/Input";
import Button from "../components/Button";
import "../styles/editHeader.scss";
import "firebase/firestore";
import "firebase/storage";
import firebase from "../Config/firebaseConfig";
import LoadingSpinner from "../components/LoadingSpinner";
import AddImage from "../components/AddImage";

const db = firebase.firestore();
const storage = firebase.storage();
const storageRef = storage.ref();

const EditHeader = ({ history }) => {
  const [header, setHeader] = useState([]);
  const [error, setError] = useState(null);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);

  const updateHeader = async () => {
    const uploaded = [];

    const uploadImages = () => {
      if (images.length > 0) {
        images.forEach(image => {
          setLoading(true);
          if (image) uploaded.push(image);
          image &&
            storageRef
              .child(`/header/${image.name}`)
              .put(image)
              .on("state_changed", snapshot => {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                if (progress === 100) uploaded.pop();
                if (progress === 100 && uploaded.length === 0) {
                  updateDb();
                  setLoading(false);
                  history.push("/");
                }
              });
        });
      } else {
        updateDb();
        setLoading(false);
        history.push("/");
      }
    };

    const updateDb = () =>
      db
        .collection("header")
        .doc("header")
        .update(header)
        .catch(error => {
          setError(error);
        });

    uploadImages();
  };

  const handleOnChange = (e, field, num = "") => {
    let newHeader = { ...header };
    let imageArr = [...images];

    if (field.includes("images") && e.target.files) {
      newHeader.images[num] = e.target.files[0].name;
      imageArr[num] = e.target.files[0];
      setImages(imageArr);
    } else {
      newHeader[field] = e.target.value;
    }

    setHeader(newHeader);
  };

  useEffect(() => {
    db.collection("header")
      .doc("header")
      .get()
      .then(doc => {
        setHeader(doc.data());
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <div className="edit-header">
      {loading && <LoadingSpinner />}
      <h2>Edit Header</h2>
      <div className="edit-header__container">
        <h3>Information</h3>
        <div className="edit-header__section">
          <Input
            onChange={e => handleOnChange(e, "intro")}
            value={header.intro}
            placeholder="Intro"
            maxLength="30"
          />
          <Input
            onChange={e => handleOnChange(e, "title")}
            value={header.title}
            placeholder="Title"
            maxLength="30"
          />
          <Input
            onChange={e => handleOnChange(e, "subtitle")}
            value={header.subtitle}
            placeholder="Subtitle"
            maxLength="40"
          />
          <Input
            onChange={e => handleOnChange(e, "address")}
            value={header.address}
            placeholder="Address"
            maxLength="100"
          />
        </div>
        <h3>Background Images</h3>
        <div className="edit-header__section">
          <AddImage
            handleOnChange={e => handleOnChange(e, "images", 0)}
            label="Image 1"
            value={header.images && header.images[0]}
          />
          <AddImage
            handleOnChange={e => handleOnChange(e, "images", 1)}
            label="Image 2"
            value={header.images && header.images[1]}
          />
          <AddImage
            handleOnChange={e => handleOnChange(e, "images", 2)}
            label="Image 3"
            value={header.images && header.images[2]}
          />
          <AddImage
            handleOnChange={e => handleOnChange(e, "images", 3)}
            label="Image 4"
            value={header.images && header.images[3]}
          />
          <AddImage
            handleOnChange={e => handleOnChange(e, "images", 4)}
            label="Image 5"
            value={header.images && header.images[4]}
          />
        </div>
      </div>
      <Button
        disabled={Boolean(error)}
        type="submit"
        text="Update Header"
        onClick={updateHeader}
      />
      {error && <p className="error-message">{error.message}</p>}
    </div>
  );
};

export default EditHeader;
