import React, { useState, useEffect } from "react";
import Input from "../components/Input";
import Button from "../components/Button";
import "../styles/editAwards.scss";
import "firebase/firestore";
import "firebase/storage";
import firebase from "../Config/firebaseConfig";
import LoadingSpinner from "../components/LoadingSpinner";
import AddImage from "../components/AddImage";

const db = firebase.firestore();
const storage = firebase.storage();
const storageRef = storage.ref();

const EditAwards = ({ history }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [images, setImages] = useState([]);
  const [numAwards, setNumAwards] = useState(null);
  const [loading, setLoading] = useState(true);

  const updateAwards = async () => {
    const x = numAwards - data.length;
    const num = x > 0 && new Array(numAwards - data.length).fill("");
    const uploaded = [];

    const uploadImages = () => {
      if (images.length > 0) {
        images.forEach(image => {
          setLoading(true);
          if (image) uploaded.push(image);
          image &&
            storageRef
              .child(`/awards/${image.name}`)
              .put(image)
              .on("state_changed", snapshot => {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                if (progress === 100) uploaded.pop();
                if (progress === 100 && uploaded.length === 0) {
                  updateAwards();
                  removeAwards();
                  setLoading(false);
                  history.push("/");
                }
              });
        });
      } else {
        updateAwards();
        removeAwards();
        setLoading(false);
        history.push("/");
      }
    };

    const updateAwards = () =>
      data.map((award, i) =>
        db
          .collection("awards")
          .doc(`award${i + 1}`)
          .set(award)
          .catch(() => setError(error))
      );

    const removeAwards = () =>
      num &&
      num.map(
        (x, i) =>
          num.length > 0 &&
          db
            .collection("awards")
            .doc(`award${data.length + i + 1}`)
            .delete()
            .catch(() => setError(error))
      );

    uploadImages();
  };

  const handleOnChange = (e, field, num = "") => {
    let newData = [...data];
    let award = { ...newData[num] };
    let imageArr = [...images];

    if (field.includes("image") && e.target.files) {
      award.image = e.target.files[0].name;
      imageArr[num] = e.target.files[0];
      setImages(imageArr);
    } else {
      award[field] = e.target.value;
    }
    newData[num] = award;
    setData(newData);
  };

  useEffect(() => {
    let arr = [];
    const getData = async () => {
      await db
        .collection("awards")
        .get()
        .then(docs => {
          docs.forEach(doc => arr.push(doc.data()));
          setData(arr);
          setNumAwards(arr.length);
          setLoading(false);
        });
    };

    getData();
  }, []);

  const removeAward = num => {
    let newData = [...data];

    newData.splice(num, 1);
    setData(newData);
  };

  const addAward = () => {
    setData(prevState => [...prevState, { title: "", content: "", image: "" }]);
  };

  return (
    data && (
      <div className="edit-awards">
        {loading && <LoadingSpinner />}
        <h2>Edit Awards</h2>
        {data.map((award, i) => (
          <React.Fragment key={i + 1}>
            <h3>Award {i + 1}</h3>
            <div className="edit-awards__container">
              <img
                src={require("../imgs/deleteIcon.svg")}
                className="delete-icon"
                alt="delete"
                onClick={() => removeAward(i)}
              />
              <div className="edit-awards__section">
                <Input
                  onChange={e => handleOnChange(e, "title", i)}
                  value={award.title}
                  placeholder="Title"
                />
                <AddImage
                  handleOnChange={e => handleOnChange(e, "image", i)}
                  label="Image"
                  value={award.image}
                />
                <Input
                  type="textarea"
                  maxLength="500"
                  onChange={e => handleOnChange(e, "content", i)}
                  value={award.content}
                  placeholder="Content"
                />
              </div>
            </div>
          </React.Fragment>
        ))}
        <div className="edit-awards__buttons">
          <Button
            type="submit"
            text="Add Award"
            onClick={addAward}
            alternate={true}
          />
          <Button
            disabled={Boolean(error)}
            type="submit"
            text="Update Awards"
            onClick={updateAwards}
          />
        </div>
        {error && <p className="error-message">{error.message}</p>}
      </div>
    )
  );
};

export default EditAwards;
