import React, { useState, useEffect } from 'react';
import firebase from '../Config/firebaseConfig';
import 'firebase/firestore';
import 'firebase/storage';

const storage = firebase.storage();
const storageRef = storage.ref();

const Award = ({ award: { title, content, image }, side }) => {
  const [img, setImg] = useState([]);

  useEffect(() => {
    storageRef.child(`awards/${image}`).getDownloadURL().then((url) => {
      setImg(url)
    })
  }, [image]);

  return side === 'right' ? (
    <div className={`award award--${side}`}>
      <div className="award__image__container">
        <img className="award__image" src={img} alt="award" />
      </div>
      <div className="award__content">
        <h2>{title}</h2>
        <p>{content}</p>
      </div>
    </div>
  ) : (
      <div className={`award award--${side}`}>
        <div className="award__content">
          <h2>{title}</h2>
          <p>{content}</p>
        </div>
        <div className="award__image__container">
          <img className="award__image" src={img} alt="award" />
        </div>
      </div>
    );
};

export default Award;
