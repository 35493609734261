import React, { useEffect, useState } from 'react';
import '../styles/show.scss';
import Show from '../components/Show';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentTab } from '../actions/setCurrentTab';
import VisibilitySensor from 'react-visibility-sensor';
import { Link } from 'react-router-dom';
import firebase from '../Config/firebaseConfig';

const Shows = () => {
  const isSignedIn = useSelector(state => state.authReducer.isSignedIn);
  const currentTab = useSelector(state => state.currentTabReducer.currentTab);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const onVisibilityChange = isVisible => {
    return isVisible && currentTab !== 'shows' ? dispatch(setCurrentTab('shows')) : null
  };


  useEffect(() => {
    const db = firebase.firestore();
    let arr = []

    db.collection('shows').get().then(docs => {

      docs.forEach(doc => {
        arr = [...arr, doc.data()]
        setData(arr);
      })

      return () => { }
    })
  }, [])

  return data && (
    <VisibilitySensor
      onChange={onVisibilityChange}
      partialVisibility
      offset={{ top: window.innerHeight / 2, bottom: window.innerHeight / 2 }}
      intervalCheck
    >
      <div className="shows">

        <h1 data-aos="fade" className="title">
          Shows
          </h1>
        {isSignedIn && <Link to='/edit/shows' className='edit'>
          <span>Edit Shows</span>
          <img alt='edit' src={require('../imgs/editIcon.svg')} />
        </Link>}
        {data.map((show, i) => (
          <Show
            show={show}
            key={i}
            id={i}
            side={i % 2 === 0 ? 'right' : 'left'}
          />
        ))}
      </div>
    </VisibilitySensor>
  );
}


export default Shows;
