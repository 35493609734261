import React, { useState, useEffect } from "react";
import Input from "../components/Input";
import Button from "../components/Button";
import "../styles/editShows.scss";
import "firebase/firestore";
import "firebase/storage";
import firebase from "../Config/firebaseConfig";
import LoadingSpinner from "../components/LoadingSpinner";
import AddImage from "../components/AddImage";

const db = firebase.firestore();
const storage = firebase.storage();
const storageRef = storage.ref();

const EditShows = ({ history }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [images, setImages] = useState([]);
  const [numShows, setNumShows] = useState(null);
  const [loading, setLoading] = useState(true);

  const updateShows = async () => {
    const x = numShows - data.length;
    const num = x > 0 && new Array(numShows - data.length).fill("");
    const uploaded = [];

    const uploadImages = () => {
      if (images.length > 0) {
        images.forEach(image => {
          setLoading(true);
          if (image) uploaded.push(image);
          image &&
            storageRef
              .child(`/shows/${image.name}`)
              .put(image)
              .on("state_changed", snapshot => {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                if (progress === 100) uploaded.pop();
                if (progress === 100 && uploaded.length === 0) {
                  updateShows();
                  removeShows();
                  setLoading(false);
                  history.push("/");
                }
              });
        });
      } else {
        updateShows();
        removeShows();
        setLoading(false);
        history.push("/");
      }
    };

    const updateShows = () =>
      data.map(
        (show, i) =>
          show &&
          db
            .collection("shows")
            .doc(`show${i + 1}`)
            .set(show)
            .catch(error => setError(error))
      );

    const removeShows = () =>
      num &&
      num.map(
        (x, i) =>
          num.length > 0 &&
          db
            .collection("shows")
            .doc(`show${data.length + i + 1}`)
            .delete()
            .catch(error => setError(error))
      );

    uploadImages();
  };

  const handleOnChange = (e, field, num = "", i) => {
    let newData = [...data];
    let show = { ...newData[num] };
    let imageArr = [...images];

    if (field.includes("images") && e.target.files) {
      const x = num * 5;
      const y = x + i;

      show.images[i] = e.target.files[0].name;
      imageArr[y] = e.target.files[0];
      setImages(imageArr);
    } else {
      show[field] = e.target.value;
    }
    newData[num] = show;
    setData(newData);
  };

  useEffect(() => {
    let arr = [];
    const getData = async () => {
      await db
        .collection("shows")
        .get()
        .then(docs => {
          docs.forEach(doc => arr.push(doc.data()));
          setData(arr);
          setNumShows(arr.length);
          setLoading(false);
        });
    };

    getData();
  }, []);

  const removeShow = num => {
    let newData = [...data];

    newData.splice(num, 1);
    setData(newData);
  };

  const addShow = () => {
    setData(prevState => [
      ...prevState,
      { title: "", content: "", images: new Array(5).fill("") }
    ]);
  };

  return (
    data && (
      <div className="edit-shows">
        {loading && <LoadingSpinner />}
        <h2>Edit Shows</h2>
        {data.map((show, i) => (
          <React.Fragment key={i + 1}>
            <h3>Show {i + 1}</h3>
            <div className="edit-shows__container">
              <img
                src={require("../imgs/deleteIcon.svg")}
                className="delete-icon"
                alt="delete"
                onClick={() => removeShow(i)}
              />
              <h4>Information</h4>
              <div className="edit-shows__section">
                <Input
                  onChange={e => handleOnChange(e, "title", i)}
                  value={show.title}
                  placeholder="Title"
                />
                <Input
                  type="textarea"
                  maxLength="500"
                  onChange={e => handleOnChange(e, "content", i)}
                  value={show.content}
                  placeholder="Content"
                />
              </div>
              <h4>Images</h4>
              <div className="edit-shows__section">
                <AddImage
                  handleOnChange={e => handleOnChange(e, "images", i, 0)}
                  label="Image 1"
                  value={show.images && show.images[0]}
                />
                <AddImage
                  handleOnChange={e => handleOnChange(e, "images", i, 1)}
                  label="Image 2"
                  value={show.images && show.images[1]}
                />
                <AddImage
                  handleOnChange={e => handleOnChange(e, "images", i, 2)}
                  label="Image 3"
                  value={show.images && show.images[2]}
                />
                <AddImage
                  handleOnChange={e => handleOnChange(e, "images", i, 3)}
                  label="Image 4"
                  value={show.images && show.images[3]}
                />
                <AddImage
                  handleOnChange={e => handleOnChange(e, "images", i, 4)}
                  label="Image 5"
                  value={show.images && show.images[4]}
                />
              </div>
            </div>
          </React.Fragment>
        ))}
        <div className="edit-shows__buttons">
          <Button
            type="submit"
            text="Add Show"
            onClick={addShow}
            alternate={true}
          />
          <Button
            disabled={Boolean(error)}
            type="submit"
            text="Update Shows"
            onClick={updateShows}
          />
        </div>
        {error && <p className="error-message">{error.message}</p>}
      </div>
    )
  );
};

export default EditShows;
