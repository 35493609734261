import React, { useEffect, useState } from "react";
import "../styles/singing.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTab } from "../actions/setCurrentTab";
import VisibilitySensor from "react-visibility-sensor";
import { Link } from "react-router-dom";
import firebase from "../Config/firebaseConfig";
import "firebase/firestore";
import "firebase/storage";

AOS.init({
  once: false,
  mirror: true,
  anchorPlacement: "top-bottom"
});

const storage = firebase.storage();
const storageRef = storage.ref();

const Singing = () => {
  const dispatch = useDispatch();
  const isSignedIn = useSelector(state => state.authReducer.isSignedIn);
  const [data, setData] = useState(null);
  const [image, setImage] = useState("");

  const onVisibilityChange = isVisible => {
    return isVisible ? dispatch(setCurrentTab("workshop")) : null;
  };

  useEffect(() => {
    const db = firebase.firestore();

    db.collection("singing")
      .doc("singing")
      .get()
      .then(doc => {
        setData(doc.data());

        storageRef
          .child(`singing/${doc.data().image}`)
          .getDownloadURL()
          .then(url => {
            setImage(url);
          });
      });

    return () => {};
  }, []);

  return (
    data && (
      <VisibilitySensor
        onChange={onVisibilityChange}
        partialVisibility
        offset={{ top: window.innerHeight / 2, bottom: window.innerHeight / 2 }}
        intervalCheck
      >
        <div className="singing__container">
          {isSignedIn && (
            <Link to="/edit/singing" className="edit">
              <span>Edit Singing</span>
              <img alt="edit" src={require("../imgs/editIcon.svg")} />
            </Link>
          )}
          <div className="singing">
            <div className="singing__image" data-aos="fade-right">
              <img alt="singing" src={image} />
            </div>
            <div data-aos="fade-left" className="singing__content">
              <h1 className="title">{data.title}</h1>
              <p>{data.content}</p>
            </div>
          </div>
        </div>
      </VisibilitySensor>
    )
  );
};

export default Singing;
