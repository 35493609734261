import React, { useState, useEffect } from "react";
import Input from "../components/Input";
import Button from "../components/Button";
import "../styles/editSinging.scss";
import "firebase/firestore";
import "firebase/storage";
import firebase from "../Config/firebaseConfig";
import LoadingSpinner from "../components/LoadingSpinner";
import AddImage from "../components/AddImage";

const db = firebase.firestore();
const storage = firebase.storage();
const storageRef = storage.ref();

const EditSinging = ({ history }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(true);

  const updateSinging = async () => {
    setLoading(true);
    const uploadImage = () => storageRef
      .child(`/singing/${image.name}`)
      .put(image)
      .on("state_changed", snapshot => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if (progress === 100) {
          updateDb();
          setLoading(false);
          history.push("/");
        }
      }, error => {
        setError(error)
        setLoading(false)
      });

    const updateDb = () =>
      db
        .collection("singing")
        .doc("singing")
        .update(data)
        .catch(error => {
          setError(error);
        });

    if (image) uploadImage()
    else {
      updateDb();
      setLoading(false);
      history.push("/");
    }
  };

  const handleOnChange = (e, field, num = "") => {
    let newData = { ...data };

    if (field.includes("image") && e.target.files) {
      newData.image = e.target.files[0].name;
      setImage(e.target.files[0]);
    } else {
      newData[field] = e.target.value;
    }

    setData(newData);
  };

  useEffect(() => {
    db.collection("singing")
      .doc("singing")
      .get()
      .then(doc => {
        setData(doc.data());
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => window.scrollTo(0, 0), []);
  
  return (
    <div className="edit-singing">
      {loading && <LoadingSpinner />}
      <h2>Edit singing</h2>
      <div className="edit-singing__container">
        <h3>Information</h3>
        <div className="edit-singing__section">
          <Input
            onChange={e => handleOnChange(e, "title")}
            value={data.title}
            placeholder="Title"
          />
          <Input
            type="textarea"
            maxLength="500"
            onChange={e => handleOnChange(e, "content")}
            value={data.content}
            placeholder="Content"
          />
        </div>
        <h3>Image</h3>
        <div className="edit-singing__section">
          <AddImage
            handleOnChange={e => handleOnChange(e, "image", 0)}
            label="Image"
            value={data.image}
          />
        </div>
      </div>
      <Button
        disabled={Boolean(error)}
        type="submit"
        text="Update singing"
        onClick={updateSinging}
      />
      {error && <p className="error-message">{error.message}</p>}
    </div>
  );
};

export default EditSinging;
