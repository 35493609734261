import React, { useState, useEffect } from "react";
import Input from "../components/Input";
import Button from "../components/Button";
import "../styles/editStudio.scss";
import "firebase/firestore";
import "firebase/storage";
import firebase from "../Config/firebaseConfig";
import LoadingSpinner from "../components/LoadingSpinner";
import AddImage from "../components/AddImage";

const db = firebase.firestore();
const storage = firebase.storage();
const storageRef = storage.ref();

const EditStudio = ({ history }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [images, setImages] = useState(new Array(5));
  const [loading, setLoading] = useState(true);

  const updateStudio = async () => {
    const uploaded = [];

    const uploadImages = () => {
      if (images.length > 0) {
        images.forEach(image => {
          setLoading(true);
          if (image) uploaded.push(image);
          image &&
            storageRef
              .child(`/studio/${image.name}`)
              .put(image)
              .on("state_changed", snapshot => {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                if (progress === 100) uploaded.pop();
                if (progress === 100 && uploaded.length === 0) {
                  updateDb();
                  setLoading(false);
                  history.push("/");
                }
              });
        });
      } else {
        updateDb();
        setLoading(false);
        history.push("/");
      }
    };

    const updateDb = () =>
      db
        .collection("studio")
        .doc("studio")
        .update(data)
        .catch(error => {
          setError(error);
        });

    uploadImages();
  };

  const handleOnChange = (e, field, num = "") => {
    let newData = { ...data };
    let imageArr = [...images];

    if (field.includes("images") && e.target.files) {
      newData.images[num] = e.target.files[0].name;
      imageArr[num] = e.target.files[0];
      setImages(imageArr);
    } else {
      newData[field] = e.target.value;
    }

    setData(newData);
  };

  useEffect(() => {
    db.collection("studio")
      .doc("studio")
      .get()
      .then(doc => {
        setData(doc.data());
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <div className="edit-studio">
      {loading && <LoadingSpinner />}
      <h2>Edit Studio</h2>
      <div className="edit-studio__container">
        <h3>Information</h3>
        <div className="edit-studio__section">
          <Input
            onChange={e => handleOnChange(e, "title")}
            value={data.title}
            placeholder="Title"
          />
          <Input
            type="textarea"
            maxLength="500"
            onChange={e => handleOnChange(e, "content")}
            value={data.content}
            placeholder="Content"
          />
        </div>
        <h3>Images</h3>
        <div className="edit-studio__section">
          <AddImage
            handleOnChange={e => handleOnChange(e, "images", 0)}
            label="Image 1"
            value={data.images && data.images[0]}
          />
          <AddImage
            handleOnChange={e => handleOnChange(e, "images", 1)}
            label="Image 2"
            value={data.images && data.images[1]}
          />
          <AddImage
            handleOnChange={e => handleOnChange(e, "images", 2)}
            label="Image 3"
            value={data.images && data.images[2]}
          />
          <AddImage
            handleOnChange={e => handleOnChange(e, "images", 3)}
            label="Image 4"
            value={data.images && data.images[3]}
          />
          <AddImage
            handleOnChange={e => handleOnChange(e, "images", 4)}
            label="Image 5"
            value={data.images && data.images[4]}
          />
        </div>
      </div>
      <Button
        disabled={Boolean(error)}
        type="submit"
        text="Update Studio"
        onClick={updateStudio}
      />
      {error && <p className="error-message">{error.message}</p>}
    </div>
  );
};

export default EditStudio;
