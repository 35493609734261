import React, { useState, useEffect } from "react";
import TeamMember from "../components/TeamMember";
import "../styles/team.scss";

const Team = ({ team }) => {
  const [current, setCurrent] = useState(null);

  const handleOnClick = teamMember =>
    team.map(() => (teamMember !== current ? setCurrent(teamMember) : null));

  useEffect(() => setCurrent(team[0]), [team]);

  return (
    <>
      <div className="team__list__container">
        <div className="team__list">
          <div data-aos="fade-up" className="team__list__inner">
            {team.map((teamMember, i) =>
              teamMember === current ? (
                <TeamMember
                  teamMember={teamMember}
                  className="active"
                  onClick={() => handleOnClick(teamMember)}
                  key={i}
                />
              ) : (
                <TeamMember
                  teamMember={teamMember}
                  key={i}
                  onClick={() => handleOnClick(teamMember)}
                />
              )
            )}
          </div>
        </div>
      </div>
      {team.map(
        (teamMember, i) =>
          teamMember === current && (
            <p data-aos="fade-up" className="team-member__description" key={i}>
              {teamMember.bio}
            </p>
          )
      )}
    </>
  );
};

export default Team;
