import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Header from "../containers/Header";
import Events from "../containers/Events";
import About from "../containers/About";
import Awards from "../containers/Awards";
import Contact from "../containers/Contact";
import Exams from "../containers/Exams";
import Shows from "../containers/Shows";
import Singing from "../containers/Singing";
import Studio from "../containers/Studio";
import Team from "../containers/Team";
import Timetable from "../containers/Timetable";
import Workshop from "../containers/Workshop";

import "../styles/home.scss";

const Home = () => {
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    /* eslint-disable */
    <div className="home">
      <Navbar />
      <a id="header" name="header" href={null} />
      <Header />
      <a id="event" name="event" className="navAnchor" href={null} />
      <Events />
      <a id="about" name="about" className="navAnchor" href={null} />
      <About />
      <a id="team" name="team" className="navAnchor" href={null} />
      <Team />
      <a id="studio" name="studio" className="navAnchor" href={null} />
      <Studio />
      <a id="workshop" name="workshop" className="navAnchor" href={null} />
      <Workshop />
      <Singing />
      <Exams />
      <a id="shows" name="shows" className="navAnchor" href={null} />
      <Shows />
      <a id="awards" name="awards" className="navAnchor" href={null} />
      <Awards />
      <a id="timetable" name="timetable" className="navAnchor" href={null} />
      <Timetable />
      <a id="contact" name="contact" className="navAnchor" href={null} />
      <Contact />
    </div>
    /* eslint-enable */
  );
};

export default Home;
