import React, { useState } from 'react';
import Input from '../components/Input';
import Button from '../components/Button';
import '../styles/addUser.scss';
import firebase from '../Config/firebaseConfig';
import 'firebase/auth';
import 'firebase/firestore';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

const db = firebase.firestore();

const AddUser = () => {
  const isSignedIn = useSelector(state => state.authReducer.isSignedIn);
  const [name, changeName] = useState('');
  const [email, changeEmail] = useState('');
  const [password, changePassword] = useState('');
  const [error, showError] = useState(false);
  const [redirect, handleRedirect] = useState(false);

  const handleAddUser = user => {
    user.preventDefault();

    firebase.auth().createUserWithEmailAndPassword(email, password).then(newUser => {
      db.collection('users').doc(newUser.user.email).set({ 'name': name, 'email': email, 'id': newUser.user.uid })
        .then(() => handleRedirect(true))
        .catch(error => {
          showError(error);
        })
        .catch(error => {
          showError(error);
        });
    })
  }

  return (
    isSignedIn ? (
      <div className="add-user" >
        {redirect && <Redirect to="/" />}
        <form className="add-user__form" onSubmit={handleAddUser} >
          <h1 className="title">Add User</h1>
          <Input type="name" placeholder="Full name" value={name} onChange={e => changeName(e.target.value)} />
          <Input type="email" placeholder="Email" value={email} onChange={e => changeEmail(e.target.value)} />
          <Input type="password" placeholder="Password" value={password} onChange={e => changePassword(e.target.value)} />
          <Button text="Add User" />
          {error && <p className="error-message">{error.message}</p>}
        </form >
        <img alt="logo" src={require('../imgs/logo.svg')} />
      </div >
    )
      : <Redirect to="/" />
  );
};

export default AddUser;
