export const INITIAL_STATE = {
  currentTab: null
};

export default (state = { INITIAL_STATE }, action) => {
  switch (action.type) {
    case 'SET_CURRENT_TAB':
      return { ...state, currentTab: action.payload };

    default:
      return state;
  }
};
