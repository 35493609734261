import React, { useState, useEffect } from "react";
import "../styles/about.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentTab } from "../actions/setCurrentTab";
import VisibilitySensor from "react-visibility-sensor";
import { Link } from "react-router-dom";
import firebase from "../Config/firebaseConfig";
import "firebase/firestore";
import "firebase/storage";

AOS.init({
  once: false,
  mirror: true,
  anchorPlacement: "top-bottom"
});

const storage = firebase.storage();
const storageRef = storage.ref();

const About = () => {
  const isSignedIn = useSelector(state => state.authReducer.isSignedIn);
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [images, setImages] = useState([]);

  const onVisibilityChange = isVisible => {
    return isVisible ? dispatch(setCurrentTab("about")) : null;
  };

  useEffect(() => {
    const db = firebase.firestore();

    db.collection("aboutUs")
      .doc("aboutUs")
      .get()
      .then(doc => {
        setData(doc.data());

        doc.data().images.map(image =>
          storageRef
            .child(`aboutUs/${image}`)
            .getDownloadURL()
            .then(url => {
              setImages(prevState => [...prevState, url]);
            })
        );
      });

    return () => {};
  }, []);

  return (
    data && (
      <VisibilitySensor
        onChange={onVisibilityChange}
        partialVisibility
        offset={{ top: window.innerHeight / 2, bottom: window.innerHeight / 2 }}
        intervalCheck
      >
        <div
          style={{
            marginTop: "60px",
            display: "flex",
            flexDirection: "column"
          }}
        >
          {isSignedIn && (
            <Link to="/edit/about" className="edit">
              <span>Edit About Us</span>
              <img alt="edit" src={require("../imgs/editIcon.svg")} />
            </Link>
          )}
          <div className="about">
            <div data-aos="fade-right" className="about__content">
              <h1 className="title">{data.title}</h1>
              <p>{data.content}</p>
            </div>
            <div className="about__images__container" data-aos="fade-left">
              <ul className="slideshow about__images">
                {images &&
                  images.map((image, i) => (
                    <li
                      key={i + 1}
                      style={{ backgroundImage: `url(${image})` }}
                    />
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </VisibilitySensor>
    )
  );
};

export default About;
