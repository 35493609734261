import React, { useEffect, useState } from "react";
import "../styles/show.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import firebase from "../Config/firebaseConfig";
import "firebase/firestore";
import "firebase/storage";

const storage = firebase.storage();
const storageRef = storage.ref();

AOS.init({
  once: false,
  mirror: true,
  anchorPlacement: "top-bottom"
});

const Show = ({ show: { title, content, images }, side, id }) => {
  const [imgs, setImgs] = useState([]);

  useEffect(() => {
    images.map(image =>
      storageRef
        .child(`shows/${image}`)
        .getDownloadURL()
        .then(url => {
          setImgs(prevState => [...prevState, url]);
        })
    );
  }, [images]);

  return (
    <div data-aos="fade" className={`show show--${side} show__${id}`}>
      <div className={`show__section show__section--${side}`}>
        <div className={`show__content show__content--${side}`}>
          <h1 className="title">{title}</h1>
          <p>{content}</p>
        </div>
      </div>
      <ul className="slideshow show__images">
        {imgs.map((image, i) => (
          <li
            key={i}
            style={{
              backgroundImage: `url(${image})`
            }}
          />
        ))}
      </ul>
    </div>
  );
};

export default Show;
