import React, { useState, useEffect } from 'react';
import '../styles/team.scss';
import 'firebase/firestore';
import 'firebase/storage';
import firebase from '../Config/firebaseConfig';

const storage = firebase.storage();
const storageRef = storage.ref();
const profileIcon = require('../imgs/icon-profile.svg')

const TeamMember = ({ teamMember }) => {
  const [source, setSource] = useState(null)

  useEffect(() => {
    const getSource = async () => storageRef.child(`team/${teamMember.image}`).getDownloadURL().then((url) => {
      setSource(url);
    })

    if(teamMember.image) getSource()
    else setSource(profileIcon)
  }, [teamMember.image]);

  return (
    <div className="team-member__mobile">
      <div className="team-member__mobile__header">
        <div className='team-member__mobile__image'>
          <img alt={`${teamMember.name}`} src={source} />
        </div>
        <h2>{teamMember.name}</h2>
      </div>

      <p>{teamMember.bio}</p>
    </div>
  );
};

export default TeamMember;
