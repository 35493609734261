import React, { useEffect, useState } from "react";
import "../styles/workshop.scss";
import { useDispatch, useSelector } from "react-redux";
import AOS from "aos";
import { setCurrentTab } from "../actions/setCurrentTab";
import VisibilitySensor from "react-visibility-sensor";
import { Link } from "react-router-dom";
import firebase from "../Config/firebaseConfig";
import "firebase/firestore";
import "firebase/storage";

AOS.init({
  mirror: true,
  anchorPlacement: "top-bottom"
});

const storage = firebase.storage();
const storageRef = storage.ref();

const Workshop = props => {
  const dispatch = useDispatch();
  const isSignedIn = useSelector(state => state.authReducer.isSignedIn);
  const [data, setData] = useState(null);
  const [images, setImages] = useState([]);

  const onVisibilityChange = isVisible => {
    return isVisible ? dispatch(setCurrentTab("workshop")) : null;
  };

  useEffect(() => {
    const db = firebase.firestore();

    db.collection("workshop")
      .doc("workshop")
      .get()
      .then(doc => {
        setData(doc.data());

        doc.data().images.map(image =>
          storageRef
            .child(`workshop/${image}`)
            .getDownloadURL()
            .then(url => {
              setImages(prevState => [...prevState, url]);
            })
        );
      });

    return () => {};
  }, []);

  return (
    data && (
      <VisibilitySensor
        onChange={onVisibilityChange}
        partialVisibility
        offset={{ top: window.innerHeight / 2, bottom: window.innerHeight / 2 }}
        intervalCheck
      >
        <div className="workshop__container">
          {isSignedIn && (
            <Link to="/edit/workshop" className="edit">
              <span>Edit Workshop</span>
              <img alt="edit" src={require("../imgs/editIcon.svg")} />
            </Link>
          )}
          <div className="workshop">
            <div className="workshop__inner">
              <div className="workshop__content" data-aos="fade-right">
                <h1 className="title">{data.title}</h1>
                <p>{data.content}</p>
              </div>
            </div>
            <div className="workshop__images__container" data-aos="fade-left">
              <ul className="slideshow workshop__images">
                {images &&
                  images.map((image, i) => (
                    <li
                      key={i + 1}
                      style={{ backgroundImage: `url(${image})` }}
                    />
                  ))}
              </ul>
            </div>
          </div>
          {props.currentTab === "workshop" && (
            <h2 className="scroll-message">Scroll Down</h2>
          )}
        </div>
      </VisibilitySensor>
    )
  );
};

export default Workshop;
