import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "firebase/firestore";
import "firebase/storage";
import firebase from "../Config/firebaseConfig";

const storage = firebase.storage();
const storageRef = storage.ref();

const defaultImg = require("../imgs/logo.svg");

AOS.init({
  mirror: true,
  anchorPlacement: "top-bottom"
});

const Event = ({ event: { date, image, name, location, time, price } }) => {
  const [source, setSource] = useState(null);

  useEffect(() => {
    const getSource = async () =>
      storageRef
        .child(`events/${image}`)
        .getDownloadURL()
        .then(url => {
          setSource(url);
        })
        .catch(() => {
          setSource(defaultImg);
        });

    getSource();
  }, [image]);

  return (
    <div className="event" data-aos="flip-down">
      <div className="event__image-container">
        <img alt="event" src={source} />
      </div>
      <div className="event__content">
        <div className="event__header">
          <div className="event__date">
            <h4>{date.slice(0, 3)}</h4>
            <h2>{date.slice(3)}</h2>
          </div>
          <div className="event__name">
            <h3>{name}</h3>
          </div>
        </div>
        <div className="event__details">
          <div className="event__location">
            <div className="event__icon">
              <img
                alt="location icon"
                src={require("../imgs/locationIcon.svg")}
              />
            </div>
            <p>{location}</p>
          </div>
          <div className="event__time">
            <div className="event__icon">
              <img alt="time icon" src={require("../imgs/timeIcon.svg")} />
            </div>
            <p>{time}</p>
          </div>
          <div className="event__price">
            <div className="event__icon">
              <img alt="price icon" src={require("../imgs/priceIcon.svg")} />
            </div>
            <p>{price}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Event;
