import React, { useEffect, useState } from "react";
import Event from "../components/Event";
import "../styles/events.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentTab } from "../actions/setCurrentTab";
import VisibilitySensor from "react-visibility-sensor";
import { Link } from "react-router-dom";
import firebase from "../Config/firebaseConfig";
import "firebase/firestore";
import "firebase/storage";

AOS.init({
  once: false,
  mirror: true,
  anchorPlacement: "top-bottom"
});

const Events = () => {
  const [events, setEvents] = useState([]);
  const dispatch = useDispatch();
  const isSignedIn = useSelector(state => state.authReducer.isSignedIn);

  const onVisibilityChange = isVisible => {
    return isVisible ? dispatch(setCurrentTab("events")) : null;
  };

  useEffect(() => {
    const db = firebase.firestore();

    db.collection("events")
      .get()
      .then(docs => {
        let arr = [];

        docs.forEach(doc => {
          arr = [...arr, doc.data()];
        });
        setEvents(arr);
      });
  }, []);

  return (
    <VisibilitySensor
      onChange={onVisibilityChange}
      partialVisibility
      offset={{ top: window.innerHeight / 2, bottom: window.innerHeight / 2 }}
      intervalCheck
    >
      <div id="events" className="events">
        <h1 data-aos="fade" className="title">
          Upcoming Events
        </h1>
        {isSignedIn && (
          <Link to="/edit/events" className="edit">
            <span>Edit Events</span>
            <img alt="edit" src={require("../imgs/editIcon.svg")} />
          </Link>
        )}
        <div className="events__list">
          {events &&
            events.map(
              event =>
                event.name &&
                event.date &&
                event.location && <Event event={event} key={event.name} />
            )}
        </div>
      </div>
    </VisibilitySensor>
  );
};

export default Events;
