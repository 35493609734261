import React, { useState, useEffect } from "react";
import "../styles/editTimetable.scss";
import Select from "react-select";
import firebase from "../Config/firebaseConfig";
import "firebase/firestore";
import Input from "../components/Input";
import Button from "../components/Button";

const EditTimetable = ({ history }) => {
  const [selectedDay, setSelectedDay] = useState({
    value: "Monday",
    label: "Monday"
  });
  const [selectedStudio, setSelectedStudio] = useState({
    value: "Studio 1",
    label: "Studio 1"
  });
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const db = firebase.firestore();

  const days = [
    { value: "Monday", label: "Monday" },
    { value: "Tuesday", label: "Tuesday" },
    { value: "Wednesday", label: "Wednesday" },
    { value: "Thursday", label: "Thursday" },
    { value: "Friday", label: "Friday" },
    { value: "Saturday", label: "Saturday" },
    { value: "Sunday", label: "Sunday" }
  ];
  const studios = [
    { value: "Studio 1", label: "Studio 1" },
    { value: "Studio 2", label: "Studio 2" },
    { value: "Studio 3", label: "Studio 3" }
  ];

  const updateTimetable = async () => {
    data.map(day =>
      db
        .collection("timetable")
        .doc(day.day)
        .set({ rows: day.rows })
        .catch(error => setError(error))
    );

    !error && history.push("/");
  };

  useEffect(() => {
    const db = firebase.firestore();

    let arr = [];

    db.collection("timetable")
      .get()
      .then(docs => {
        docs.forEach(doc => {
          arr.push({ day: doc.id, rows: doc.data().rows });
        });

        setData(arr);
      });

    return () => {};
  }, [selectedDay]);

  useEffect(() => window.scrollTo(0, 0), []);

  const removeRow = num => {
    let newData = [...data];
    let day = newData.findIndex(e => e.day === selectedDay.value);
    newData[day].rows.splice(num, 1);
    setData(newData);
  };

  const addRow = () => {
    let newData = [...data];
    let day = newData.findIndex(e => e.day === selectedDay.value);
    newData[day].rows.push({ time: "", studio1: "", studio2: "", studio3: "" });

    setData(newData);
  };

  const handleOnChange = (e, field, index) => {
    let newData = [...data];
    let day = newData.findIndex(e => e.day === selectedDay.value);
    newData[day].rows[index][field] = e.target.value;

    setData(newData);
  };

  return (
    data && (
      <div className="edit-timetable">
        <h2>Edit Timetable</h2>
        <div>
          <div className="timetable__content">
            <nav className="days">
              <ul>
                {days.map((day, i) =>
                  day.value === selectedDay.value ? (
                    <li key={i + 1} className="days--selected">
                      <h4>{day.value}</h4>
                    </li>
                  ) : (
                    <li
                      key={i + 1}
                      onClick={() =>
                        setSelectedDay({ value: day.value, label: day.value })
                      }
                    >
                      <h4>{day.value}</h4>
                    </li>
                  )
                )}
              </ul>
            </nav>
            <div delay="200" className="table__container">
              {data ? (
                <table>
                  <thead>
                    <tr>
                      <td className="column__times"></td>
                      <td>Studio 1</td>
                      <td>Studio 2</td>
                      <td>Studio 3</td>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map(day => {
                      if (day.day === selectedDay.value) {
                        return day.rows.map((row, i) => {
                          return (
                            <tr key={i + 1}>
                              <td className="row__time">
                                <Input
                                  onChange={e => handleOnChange(e, "time", i)}
                                  value={row.time}
                                  placeholder="Time"
                                />
                              </td>
                              <td>
                                <Input
                                  style={{
                                    backgroundColor: !row.studio1
                                      ? "#f5f5f5"
                                      : null
                                  }}
                                  onChange={e =>
                                    handleOnChange(e, "studio1", i)
                                  }
                                  value={row.studio1}
                                  placeholder="Enter Class"
                                />
                              </td>
                              <td>
                                <Input
                                  style={{
                                    backgroundColor: !row.studio2
                                      ? "#f5f5f5"
                                      : null
                                  }}
                                  onChange={e =>
                                    handleOnChange(e, "studio2", i)
                                  }
                                  value={row.studio2}
                                  placeholder="Enter Class"
                                />
                              </td>
                              <td>
                                <Input
                                  style={{
                                    backgroundColor: !row.studio3
                                      ? "#f5f5f5"
                                      : null
                                  }}
                                  onChange={e =>
                                    handleOnChange(e, "studio3", i)
                                  }
                                  value={row.studio3}
                                  placeholder="Enter Class"
                                />
                              </td>
                              <td>
                                <img
                                  src={require("../imgs/deleteIcon.svg")}
                                  className="delete-icon"
                                  alt="delete"
                                  onClick={() => removeRow(i)}
                                />
                              </td>
                            </tr>
                          );
                        });
                      } else {
                        return null;
                      }
                    })}
                  </tbody>
                </table>
              ) : (
                <h2 className="no-classes">No Classes Today</h2>
              )}
            </div>
          </div>
          <div className="timetable__content__mobile">
            <div className="timetable__options__mobile">
              <Select
                value={selectedDay}
                onChange={value => setSelectedDay(value)}
                options={days}
                className="react-select-container"
                classNamePrefix="react-select"
                isSearchable={false}
              />
              <Select
                value={selectedStudio}
                onChange={value => setSelectedStudio(value)}
                options={studios}
                className="react-select-container"
                classNamePrefix="react-select"
                isSearchable={false}
              />
            </div>
            <div className="timetable__mobile">
              <table>
                <tbody>
                  {data.map(day => {
                    if (day.day === selectedDay.value) {
                      return day.rows.map((row, i) => {
                        if (selectedStudio.value === "Studio 1") {
                          return (
                            <tr key={i + 1}>
                              <td className="row__time">
                                <Input
                                  onChange={e => handleOnChange(e, "time", i)}
                                  value={row.time}
                                  placeholder="Time"
                                />
                              </td>
                              <td>
                                <Input
                                  style={{
                                    backgroundColor: !row.studio3
                                      ? "#f5f5f5"
                                      : null
                                  }}
                                  onChange={e =>
                                    handleOnChange(e, "studio1", i)
                                  }
                                  value={row.studio1}
                                  placeholder="Enter Class"
                                />
                              </td>
                              <td>
                                <img
                                  src={require("../imgs/deleteIcon.svg")}
                                  className="delete-icon"
                                  alt="delete"
                                  onClick={() => removeRow(i)}
                                />
                              </td>
                            </tr>
                          );
                        }
                        if (selectedStudio.value === "Studio 2") {
                          return (
                            <tr key={i + 1}>
                              <td className="row__time">
                                <Input
                                  onChange={e => handleOnChange(e, "time", i)}
                                  value={row.time}
                                  placeholder="Time"
                                />
                              </td>
                              <td>
                                <Input
                                  style={{
                                    backgroundColor: !row.studio3
                                      ? "#f5f5f5"
                                      : null
                                  }}
                                  onChange={e =>
                                    handleOnChange(e, "studio2", i)
                                  }
                                  value={row.studio2}
                                  placeholder="Enter Class"
                                />
                              </td>
                              <td>
                                <img
                                  src={require("../imgs/deleteIcon.svg")}
                                  className="delete-icon"
                                  alt="delete"
                                  onClick={() => removeRow(i)}
                                />
                              </td>
                            </tr>
                          );
                        }

                        return (
                          <tr key={i + 1}>
                            <td className="row__time">
                              <Input
                                onChange={e => handleOnChange(e, "time", i)}
                                value={row.time}
                                placeholder="Time"
                              />
                            </td>
                            <td>
                              <Input
                                style={{
                                  backgroundColor: !row.studio3
                                    ? "#f5f5f5"
                                    : null
                                }}
                                onChange={e => handleOnChange(e, "studio3", i)}
                                value={row.studio3}
                                placeholder="Enter Class"
                              />
                            </td>
                            <td>
                              <img
                                src={require("../imgs/deleteIcon.svg")}
                                className="delete-icon"
                                alt="delete"
                                onClick={() => removeRow(i)}
                              />
                            </td>
                          </tr>
                        );
                      });
                    }

                    return null
                  })}
                </tbody>
              </table>
              {!data && <h2 className="no-classes">No Classes Today</h2>}
            </div>
          </div>
        </div>
        <div className="edit-team__buttons">
          <Button
            type="submit"
            text="Add Row"
            onClick={addRow}
            alternate={true}
          />
          <Button
            disabled={Boolean(error)}
            type="submit"
            text="Update Timetable"
            onClick={updateTimetable}
          />
        </div>
        {error && <p className="error-message">{error.message}</p>}
      </div>
    )
  );
};

export default EditTimetable;
