import * as firebase from 'firebase/app';

let config = {
    apiKey: 'AIzaSyBrh99nCHmnURgNArVk6KDH7PkvhHMB8i0',
    authDomain: 'theatre-dance-studios.firebaseapp.com',
    databaseURL: 'https://theatre-dance-studios.firebaseio.com',
    projectId: 'theatre-dance-studios',
    storageBucket: 'theatre-dance-studios.appspot.com',
    messagingSenderId: '675365797157',
    appId: '1:675365797157:web:88f24998150f1889a8eecc'  
};

export default !firebase.apps.length ? firebase.initializeApp(config) : firebase.app();